import React from "react"
import Img from "gatsby-image"

import useImageQuery from "./images"

export default () => {
  return (
    <>
      <Img
        style={{
          zIndex: -1,
        }}
        className="absolute w-100 left h100vh"
        fluid={useImageQuery().placeholderImage.childImageSharp.fluid}
      />

      <div
        style={{
          background: "linear-gradient(180deg, #404543 15%, transparent)",
        }}
        className="absolute w-100 left h100vh"
      ></div>
      <div
        style={{
          zIndex: 2,
        }}
        className="d-flex align-items-center justify-content-center flex-column white absolute w-100 left h-100"
      >
        <h1 className="mb-1 text-center">
          Entreprise Tahtane Malek
          <br />
        </h1>
        <h2
          className="mb-0 mt-4"
          style={{ color: "#8bc63e", fontSize: 32, textAlign: "center" }}
        >
          CONCEPTIONS, AMÉNAGEMENTS ET ENTRETIENS D'ESPACES VERTS
        </h2>
        <button className="mt-4 actionButton">en savoir plus</button>
      </div>

      <div className="h100vh w-100"></div>
    </>
  )
}
