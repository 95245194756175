import React from "react"
import useImageQuery from "@/components/index/images"
import Img from "gatsby-image"

import FirstScreen from "@/components/index/first_screen"
import About from "@/components/about"
import Services from "@/components/services"

import Layout from "@/components/layout"
import SEO from "@/components/seo"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <FirstScreen />
      <div className="col-lg-12">
        <About />
      </div>
      <div className="col-lg-12 mt-5 p-0 pb-5">
        <Services />
      </div>
    </Layout>
  )
}

export default IndexPage
