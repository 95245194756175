import React from "react"
import Img from "gatsby-image"
import useImageQuery from "@/components/index/images"

export default () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className=" mt-5 text-center">
            <p
              style={{
                columnCount: 2,
                textAlign: "justify",
                columnGap: 36,
                fontSize: 14,
              }}
              className="mt-5"
            >
              <strong className="mr-2">L'Entreprise TAHTANE MALEK</strong>
              , spécialisée dans les travaux et prestations d'entretiens et
              d'aménagements d'espaces verts, se distingue par un savoir faire
              avéré acquis au cours de son existence, met à la disposition de sa
              clientèle un personnel qualifié et motivé, composé de Techniciens,
              de paysagiste, d'horticoles et de jardiniers formés et
              expérimentés, dans le souci d'assurer un service de proximité, de
              qualité et de suivi après intervention. <br />{" "}
              <strong className="mr-2">L'Entreprise TAHTANE MALEK</strong>, est
              à l'écoute de sa clientèle en permanence et traduit les attentes
              de chacun d'eux en cahier des charges, qui devient son challenge,
              son objectif à atteindre dans les délais convenus. <br />
              <strong className="mr-2">L'Entreprise TAHTANE MALEK</strong>
              aménage vos espaces verts dans les normes et les règles de l'art,
              en tenant compte de l'environnement et de la typologie de votre
              espace, des caractéristiques de plantation et des fonctionnalités
              attendues <br /> <strong>L'Entreprise TAHTANE MALEK</strong>
              , vous assiste et vous conseil dans votre choix d'aménagement et
              de plantation.
              <br />{" "}
              <strong className="mr-2">L'Entreprise TAHTANE MALEK</strong>
              se fera une joie de répondre à vos questions, pour cela, contactez
              nous pour toutes vos questions et pour toute étude personnalisée
              de votre chantier ou projet, nos techniciens se rendront sur les
              lieux pour vous établir un devis de travaux selon vos besoins.
            </p>
            <div className="container-fluid mt-5 p-0">
              <div className="row">
                <div className="col-lg-6">
                  {useImageQuery && (
                    <Img
                      style={{
                        height: 320,
                      }}
                      fluid={useImageQuery().topRight.childImageSharp.fluid}
                    />
                  )}
                </div>
                <div className="col-lg-6">
                  {useImageQuery && (
                    <Img
                      style={{
                        height: 320,
                      }}
                      fluid={useImageQuery().bottomRight.childImageSharp.fluid}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
